import React, { memo } from 'react';
import styled from 'styled-components';

export type Props = {
  height?: number;
  width?: number;
};

const CoRequisiteNode = memo(({ data: { height, width } }: { data: Props }) => (
  <Container height={height} width={width}>
    <Label>Co-Requisites</Label>
  </Container>
));

export default CoRequisiteNode;

const Container = styled.div<Props>`
  width: ${(props) => props.width ?? 195}px;
  height: ${(props) => props.height ?? 185}px;
  border: 2px dashed #aaa;
  border-radius: 10px;
  font-size: 11px;
  color: #777;
`;
const Label = styled.div`
  font-weight: 500px;
  padding-left: 10px;
  padding-top: 2px;
`;
