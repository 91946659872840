import sha1 from "crypto-js/sha1";
import sha256 from "crypto-js/sha256";
import sha512 from "crypto-js/sha512";
import hexEncode from "crypto-js/enc-hex";
import hexFormat from "crypto-js/format-hex";
import utf8Encode from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";

export const SHA1Hash = (plain: string) => sha1(plain).toString(hexEncode);

export const SHA256Hash = (plain: string) => sha256(plain).toString(hexEncode);

export const SHA512Hash = (plain: string) => sha512(plain).toString(hexEncode);

export const AESEncrypt = (plain: string, password: string) => {
  const keyIv = getAESKeyIV(password);
  const encrypted = AES.encrypt(plain, keyIv.key, { iv: keyIv.iv });
  return encrypted.ciphertext.toString();
};

export const AESDecrypt = (cipher: string, password: string) => {
  const keyIv = getAESKeyIV(password);
  const decrypted = AES.decrypt(cipher, keyIv.key, {
    iv: keyIv.iv,
    format: hexFormat,
  });
  return decrypted.toString(utf8Encode);
};

const convertHex = (input: string) => {
  const strArray = [] as string[];
  for (var i = 0; i < input.length; i++) {
    var char = input.charCodeAt(i);
    strArray.push((char & 0xff).toString(16));
  }
  return strArray.join("");
};

const getAESKeyIV = (password: string) => {
  const passwordHash = SHA512Hash(password);
  const keyStart = Math.pow(password.length, 3) % 96;
  const ivStart = Math.pow(password.length, 2) % 112;

  return {
    key: hexEncode.parse(
      convertHex(passwordHash.substring(keyStart, keyStart + 32))
    ),
    iv: hexEncode.parse(
      convertHex(passwordHash.substring(ivStart, ivStart + 16))
    ),
  };
};
