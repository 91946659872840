import React, { memo } from 'react';
import styled from 'styled-components';

export type Props = {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  animated?: boolean;
  data: {
    index: number;
    maxIndex: number;
    horizontalMode?: boolean;
  };
};

const CoRequisiteEdge = memo(
  ({ id, sourceX, sourceY, targetX, targetY, animated, data }: Props) => {
    const offset = 10 * (data.maxIndex - data.index);

    return (
      <>
        <marker
          id="arrowhead"
          markerWidth="5"
          markerHeight="5"
          refX="0"
          refY="2.5"
          orient="auto"
        >
          <polygon points="0 0, 5 2.5, 0 5" />
        </marker>
        <SvgEdge
          id={id}
          strokeWidth={animated ? 2 : 1.5}
          strokeDasharray={animated ? 5 : 0}
          animated={animated}
        >
          {data.horizontalMode ? (
            <>
              <line
                x1={sourceX}
                y1={sourceY}
                x2={sourceX}
                y2={sourceY - 25 - offset}
              />
              <line
                x1={sourceX}
                y1={sourceY - 25 - offset}
                x2={targetX}
                y2={sourceY - 25 - offset}
              />
              <line
                x1={targetX}
                y1={sourceY - 25 - offset}
                x2={targetX}
                y2={targetY - (animated ? 13 : 10)}
                markerEnd="url(#arrowhead)"
              />
            </>
          ) : (
            <>
              <line
                x1={sourceX}
                y1={sourceY}
                x2={sourceX - 18 - offset}
                y2={sourceY}
              />
              <line
                x1={sourceX - 18 - offset}
                y1={sourceY}
                x2={targetX - 18 - offset}
                y2={targetY}
              />
              <line
                x1={targetX - 18 - offset}
                y1={targetY}
                x2={targetX - (animated ? 13 : 10)}
                y2={targetY}
                markerEnd="url(#arrowhead)"
              />
            </>
          )}
        </SvgEdge>
      </>
    );
  }
);

export default CoRequisiteEdge;

const SvgEdge = styled.svg<{ animated?: boolean }>`
  stroke: ${(props) => (props.animated ? '#333' : '#787878')};
  ${(props) => props.animated && 'animation: dashdraw .5s linear infinite;'}
`;
