import React, { useEffect } from 'react';
import ReactFlow from 'react-flow-renderer';
import TermNode from './components/TermNode';
import CourseNode from './components/CourseNode';
import CoRequisiteNode from './components/CoRequisiteNode';
import CoRequisiteEdge from './components/CoRequisiteEdge';
import { polishProgramMap } from './helpers';
import { usePrevious, useOneState } from '@nait-aits/helper/hooks';
import type { ProgramCourseMapProps as Props } from './types';
import { getElements } from './components/Elements';

const ProgramCourseMap = <T extends object = {}>({
  courses,
  titleOffset,
  getTermTitle,
  disableEvenOffset,
  nodeOverride,
  nodeStyle,
  positionDistince,
  viewportStyle,
  nodeSize,
  connectorMode,
  selectedNodeOverride,
  horizontalMode,
}: Props<T>) => {
  const [
    { selectedId, htmlId },
    { setOnlySelectedId, setOnlyRefreshTimestamp },
  ] = useOneState({
    selectedId: undefined as string | undefined,
    refreshTimestamp: undefined as Date | undefined,
    htmlId: `programMap_${Math.random().toString(36).slice(2)}`,
  });

  const polished = polishProgramMap(courses);
  const elements = getElements(
    polished,
    connectorMode,
    getTermTitle,
    titleOffset,
    disableEvenOffset,
    nodeOverride,
    positionDistince,
    nodeStyle,
    nodeSize,
    selectedId,
    horizontalMode
  );

  const prevCourses = usePrevious(courses) ?? [];
  const prevHoriMode = usePrevious(horizontalMode);
  const diffCourses = polished.filter((c) =>
    prevCourses.find(
      (pc) =>
        pc.courseCode === c.courseCode &&
        (!c.atTermIndexes.isEqualTo(pc.atTermIndexes) ||
          !(c.ordinals ?? [undefined]).isEqualTo(pc.ordinals ?? [undefined]))
    )
  );
  const newCourses = polished.filter(
    (c) => !prevCourses.find((pc) => pc.courseCode === c.courseCode)
  );
  const groupIds = [...newCourses, ...diffCourses]
    .map((n) => n.coReqGroupId)
    .filter((g) => g !== undefined)
    .uniq();
  const diffCodes =
    (prevHoriMode ?? false) !== (horizontalMode ?? false)
      ? courses.map((c) => c.courseCode)
      : [
          ...diffCourses.map((d) => d.courseCode),
          ...newCourses.flatMap((n) => n.preRequisites),
          ...polished
            .filter((c) => groupIds.includes(c.coReqGroupId))
            .map((c) => c.courseCode),
        ].uniq();
  useEffect(() => {
    if (diffCodes.length > 0) setOnlyRefreshTimestamp(new Date());
  }, [diffCodes]);
  useEffect(() => {
    if (selectedNodeOverride?.timestamp) {
      const selected = selectedNodeOverride.node
        ? `course_${selectedNodeOverride.node.termIndex}_${selectedNodeOverride.node.courseCode}`
        : undefined;
      if (selected !== selectedId) setOnlySelectedId(selected);
    }
  }, [selectedNodeOverride?.timestamp]);

  return (
    <div
      id={htmlId}
      style={{ height: 650, ...(viewportStyle ?? {}) }}
      onClick={() => setOnlySelectedId(undefined)}
    >
      <ReactFlow
        elements={
          diffCodes.length > 0
            ? elements.filter((e) => !diffCodes.find((dc) => e.id.includes(dc)))
            : elements
        }
        nodeTypes={{
          courseNode: CourseNode,
          termNode: TermNode,
          coRequisiteNode: CoRequisiteNode,
        }}
        edgeTypes={{ coRequisiteEdge: CoRequisiteEdge }}
        paneMoveable={false}
        nodesDraggable={false}
        zoomOnScroll={false}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        onElementClick={(event, element) => {
          if (element.type === 'courseNode') {
            event.stopPropagation();
            if (selectedId !== element.id) setOnlySelectedId(element.id);
          }
        }}
        onWheel={(e) => {
          const parent = document.getElementById(htmlId)?.parentElement;
          parent?.scrollTo({ top: parent.scrollTop + e.deltaY });
        }}
      />
    </div>
  );
};

export default ProgramCourseMap;
