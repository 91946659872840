import { CSSProperties } from 'react';
import { Node, Edge } from 'react-flow-renderer';
import range from 'lodash/range';
import { Props as TermNodeProps } from './TermNode';
import { Props as CourseNodeProps } from './CourseNode';
import {
  Props as CoRequisiteNodeProps,
} from './CoRequisiteNode';
import groupBy from 'lodash/groupBy';
import type { ProgramCourseMapProps as Props, ProgramMapCourse } from '../types';

type InvalidEdge = {
  invalid?: {
    source: {
      courseCode: string;
      termIndex: number;
    };
    target: {
      courseCode: string;
      termIndex: number;
    };
  };
};

const styles: Record<
  | 'default'
  | 'selected'
  | 'related'
  | 'connDefault'
  | 'connSelected'
  | 'connInvalid',
  CSSProperties
> = {
  default: {
    padding: 8,
    backgroundColor: '#fff',
    borderRadius: 8,
    border: '1px solid #bbb',
    color: '#333',
    cursor: 'pointer',
  },
  selected: {
    padding: 8,
    backgroundColor: '#fff',
    border: '1px solid #1a73e8',
    borderRadius: 8,
    boxShadow: '0 1px 12px 0 #1a73e8, 0 1px 3px 1px rgba(60, 64, 67, 0.15)',
    cursor: 'default',
  },
  related: {
    padding: 8,
    backgroundColor: '#fff',
    border: '1px solid transparent',
    borderRadius: 8,
    boxShadow: '0 1px 5px 0 black, 0 1px 3px 1px rgba(60, 64, 67, 0.15)',
    cursor: 'pointer',
  },
  connDefault: { strokeWidth: 2, stroke: '#8e8e8e' },
  connSelected: { stroke: '#1a73e8', strokeWidth: 3 },
  connInvalid: { strokeWidth: 2, stroke: 'red' },
};

export const getElements = <T extends object = {}>(
  courses: ProgramMapCourse<T>[],
  connectorMode?: Props['connectorMode'],
  getTermTitle: Props['getTermTitle'] = () => ({}),
  titleOffset: Props['titleOffset'] = 0,
  disableEvenOffset: Props['disableEvenOffset'] = false,
  nodeOverride?: Props<T>['nodeOverride'],
  positionDistince?: Props['positionDistince'],
  nodeStyle?: Props<T>['nodeStyle'],
  nodeSize: Props['nodeSize'] = { height: 35, width: 155 },
  selectedId?: string,
  horizontalMode?: boolean
) => {
  const positionDistinceX = positionDistince?.x ?? 300;
  const positionDistinceY = positionDistince?.y ?? 150;
  const elements: (
    | Node<TermNodeProps | CourseNodeProps<T> | CoRequisiteNodeProps>
    | Edge
  )[] = [];

  const connectors = getConnectors(courses, connectorMode, selectedId);
  const relatedIds = selectedId
    ? connectors
      .filter((c) => c.animated === true)
      .map((c) => [c.source, c.target])
      .flatMap((c) => c)
      .uniq()
      .without(selectedId)
    : [];

  const numOfTerms = (courses.flatMap((c) => c.atTermIndexes).max() ?? -1) + 1;
  const terms = range(0, numOfTerms).map((n) =>
    getTermTitle(n, selectedId?.split('_')[2])
  );
  const emptyTerms: number[] = [];
  let firstCoReqCount = 0;
  terms.forEach((t, tIndex) => {
    const termCoReqConn = getTermCoRequConnectors(
      courses,
      tIndex,
      selectedId,
      horizontalMode
    );
    if (firstCoReqCount === 0 && termCoReqConn.length > 0)
      firstCoReqCount = termCoReqConn.length;
    const firstOffset = [firstCoReqCount * 15, horizontalMode ? 60 : 50].max()!;
    const termCourses = getTermCourseElements<T>(
      courses,
      tIndex,
      emptyTerms,
      titleOffset,
      disableEvenOffset,
      firstOffset,
      nodeOverride,
      positionDistince,
      nodeStyle,
      selectedId,
      connectorMode,
      horizontalMode,
      [
        ...relatedIds,
        ...termCoReqConn
          .filter((e) => e.animated)
          .flatMap((e) => [e.source, e.target])
          .without(selectedId),
      ]
    );
    if (termCourses.length === 0) emptyTerms.push(tIndex);
    else {
      elements.push(
        ...getCoRequisiteElements(
          tIndex,
          termCourses,
          nodeSize,
          positionDistince,
          horizontalMode
        )
      );
      elements.push(
        ...termCourses.map((tc) => {
          const nodeId = `course_${tIndex}_${tc.data?.course.courseCode}`;
          const customHandles = [
            ...termCoReqConn
              .filter((x) => x.source === nodeId)
              .map((x) => x.sourceHandle ?? ''),
            ...termCoReqConn
              .filter((x) => x.target === nodeId)
              .map((x) => x.targetHandle ?? ''),
          ];
          return customHandles.length > 0
            ? ({ ...tc, data: { ...tc.data, customHandles } } as Node)
            : tc;
        })
      );
      elements.push({
        id: `term_${tIndex}`,
        type: 'termNode',
        data: {
          termIndex: tIndex,
          titleOverride: t.override,
          titleExtra: t.extra,
        },
        position: horizontalMode
          ? {
            y:
              (tIndex - emptyTerms.length) * positionDistinceY +
              firstOffset -
              50,
            x: 50,
          }
          : {
            x: (tIndex - emptyTerms.length) * positionDistinceX + firstOffset,
            y: 10,
          },
        style: { cursor: 'default' },
      });
      elements.push(...termCoReqConn);
    }
  });
  elements.push(...connectors);

  return elements;
};

const sortCourses = <T extends object = {}>(
  courses: ProgramMapCourse<T>[],
  termIndex: number
) =>
  courses.orderBy(
    [(c) => c.ordinals?.[c.atTermIndexes.indexOf(termIndex)] ?? 9999],
    [(c) => c.coReqGroupId, 'desc'],
    [(c) => c.coRequisites.length],
    [(c) => c.preRequisites.length],
    [(c) => c.courseCode.toLowerCase()]
  );

const getTermCourseElements = <T extends object = {}>(
  courses: ProgramMapCourse<T>[],
  termIndex: number,
  emptyTerms: number[],
  titleOffset: number,
  disableEvenOffset: boolean,
  firstOffset: number,
  nodeOverride?: Props<T>['nodeOverride'],
  positionDistince?: Props['positionDistince'],
  nodeStyle?: Props<T>['nodeStyle'],
  selectedId?: string,
  connectorMode?: Props['connectorMode'],
  horizontalMode?: boolean,
  relatedIds?: string[]
): Node<CourseNodeProps<T>>[] => {
  const positionDistinceX = positionDistince?.x ?? (horizontalMode ? 250 : 300);
  const positionDistinceY = positionDistince?.y ?? (horizontalMode ? 150 : 95);

  return sortCourses(
    courses.filter((c) => c.atTermIndexes.includes(termIndex)),
    termIndex
  ).map((c, cIndex, array) => {
    const id = `course_${termIndex}_${c.courseCode}`;
    const style =
      selectedId === id
        ? styles.selected
        : relatedIds?.includes(id)
          ? styles.related
          : styles.default;
    const isMaxTerm = (c.atTermIndexes.max() ?? -1) === termIndex;
    const multiTargets = connectorMode === 'multiTarget';
    const sameCourse =
      connectorMode === 'sameCourse' && c.atTermIndexes.length > 1;
    const sameCourseSource = sameCourse && !isMaxTerm;
    const sameCourseTarget = sameCourse && c.atTermIndexes.min() !== termIndex;
    const positionIndex = termIndex - emptyTerms.length;

    return {
      id,
      type: 'courseNode',
      data: {
        course: c,
        termIndex,
        ordinals: array.map((a) => a.courseCode),
        isSource:
          (!!courses.find((x) => x.preRequisites.includes(c.courseCode)) &&
            (multiTargets || isMaxTerm)) ||
          sameCourseSource,
        isTarget:
          (c.preRequisites.length > 0 && (multiTargets || isMaxTerm)) ||
          sameCourseTarget,
        nodeOverride,
        selectedId,
        horizontalMode,
      },
      position: horizontalMode
        ? {
          x:
            positionDistinceX * cIndex +
            (disableEvenOffset || positionIndex % 2 === 0 ? 50 : 100),
          y: firstOffset + positionIndex * positionDistinceY + titleOffset,
        }
        : {
          x: firstOffset + positionIndex * positionDistinceX,
          y:
            positionDistinceY * cIndex +
            (disableEvenOffset || positionIndex % 2 === 0 ? 60 : 110) +
            titleOffset,
        },
      selectable: true,
      draggable: false,
      style: nodeStyle?.(c, termIndex, style) ?? style,
    };
  });
};

const getCoRequisiteElements = <T extends object = {}>(
  termIndex: number,
  elements: Node<CourseNodeProps<T>>[],
  nodeSize: NonNullable<Props['nodeSize']>,
  positionDistince?: Props['positionDistince'],
  horizontalMode?: boolean
): Node<CoRequisiteNodeProps>[] => {
  const positionDistinceX = positionDistince?.x ?? 250;
  const positionDistinceY = positionDistince?.y ?? 95;
  const coReqGroups = groupBy(
    elements.filter((e) => e.data?.course.coReqGroupId),
    (e) => e.data?.course.coReqGroupId
  );
  const ret: Node<CoRequisiteNodeProps>[] = [];
  for (const gKey in coReqGroups) {
    const coReqs = coReqGroups[gKey];
    if (coReqs.length >= 2) {
      const positions = coReqs.map((e) => e.position);
      if (positions.length > 0) {
        const yArray = positions.map((p) => p.y);
        const y = yArray.min()! - 20;
        const x = positions[0].x - 10;

        ret.push({
          id: `coreq_term_${termIndex}_id_${gKey}`,
          type: 'coRequisiteNode',
          data: horizontalMode
            ? {
              height: nodeSize.height + 45,
              width:
                positionDistinceX * coReqs.length -
                (positionDistinceX - nodeSize.width - 33),
            }
            : {
              height:
                positionDistinceY * coReqs.length -
                (positionDistinceY - nodeSize.height - 45),
              width: nodeSize.width + 33,
            },
          position: { x, y },
          selectable: false,
          style: { cursor: 'default', textAlign: 'left' },
        });
      }
    }
  }

  return ret;
};

export const getConnectors = (
  courses: ProgramMapCourse[],
  connectorMode?: Props['connectorMode'],
  selectedId?: string
): (Edge & InvalidEdge)[] => [
    ...courses
      .filter((c) => c.preRequisites.length > 0)
      .flatMap((c) =>
        c.preRequisites.flatMap((p) => {
          const pCourseTerms =
            courses.find((x) => x.courseCode === p)?.atTermIndexes ??
            ([] as number[]);
          const sources =
            connectorMode === 'multiTarget'
              ? pCourseTerms.map((a) => ({ source: `course_${a}_${p}`, term: a }))
              : [
                {
                  source: `course_${pCourseTerms.max()}_${p}`,
                  term: pCourseTerms.max()!,
                },
              ];
          const targets =
            connectorMode === 'multiTarget'
              ? c.atTermIndexes.map((a) => ({
                target: `course_${a}_${c.courseCode}`,
                term: a,
              }))
              : [
                {
                  target: `course_${c.atTermIndexes.max()}_${c.courseCode}`,
                  term: c.atTermIndexes.max()!,
                },
              ];

          return targets.flatMap((t) => {
            return sources.map((s) => {
              const isSelected =
                s.source === selectedId || t.target === selectedId;
              const isInvalid = t.term <= s.term;

              return {
                id:
                  connectorMode === 'multiTarget'
                    ? `${p}_${c.courseCode}_${s.term}to${t.term}`
                    : `${p}_${c.courseCode}`,
                source: s.source,
                target: t.target,
                type: 'straight',
                style: isInvalid
                  ? styles.connInvalid
                  : isSelected
                    ? styles.connSelected
                    : styles.connDefault,
                animated: isSelected,
                invalid: isInvalid
                  ? {
                    source: { courseCode: p, termIndex: s.term },
                    target: { courseCode: c.courseCode, termIndex: t.term },
                  }
                  : undefined,
              } as Edge & InvalidEdge;
            });
          });
        })
      ),
    ...(connectorMode !== 'sameCourse'
      ? []
      : courses
        .filter((c) => c.atTermIndexes.length > 1)
        .flatMap((c) =>
          c.atTermIndexes
            .orderBy()
            .slice(1)
            .map((a, aIndex) => {
              const sourceIndex = c.atTermIndexes.orderBy()[aIndex];
              const source = `course_${sourceIndex}_${c.courseCode}`;
              const target = `course_${a}_${c.courseCode}`;
              const isSelected = source === selectedId || target === selectedId;

              return {
                id: `${c.courseCode}_${c.courseCode}_${sourceIndex}to${a}`,
                source: source,
                target: target,
                type: 'straight',
                style: isSelected ? styles.connSelected : styles.connDefault,
                animated: isSelected,
              } as Edge;
            })
        )),
  ];

export const getTermCoRequConnectors = (
  courses: ProgramMapCourse[],
  termIndex: number,
  selectedId?: string,
  horizontalMode?: boolean
): Edge[] => {
  const coReqCourses = sortCourses(
    courses.filter(
      (c) => c.coReqGroupId !== undefined && c.atTermIndexes.max() === termIndex
    ),
    termIndex
  );
  const orders = coReqCourses.map((e, index) => ({
    courseCode: e.courseCode,
    index,
  }));
  const termCoReq = coReqCourses.flatMap((c) =>
    c.coRequisites.map((cr) => ({
      from: cr,
      to: c.courseCode,
      toBeRemove: false,
      groupId: c.coReqGroupId!,
    }))
  );
  termCoReq.forEach((e, _, all) => {
    if (
      all.find((a) => a.from === e.to && a.to === e.from) ||
      !coReqCourses.find((c) => c.courseCode === e.to) ||
      !coReqCourses.find((c) => c.courseCode === e.from)
    )
      e.toBeRemove = true;
  });
  const groups = termCoReq
    .map((e) => e.groupId)
    .uniq()
    .orderBy();

  return groups.flatMap((g) =>
    termCoReq
      .filter((e) => e.groupId === g && !e.toBeRemove)
      .orderBy(
        [(e) => orders.find((o) => o.courseCode === e.from)?.index],
        [(e) => orders.find((o) => o.courseCode === e.to)?.index, 'desc']
      )
      .map((e, index, all) => ({
        id: `coReq_${e.from}_${e.to}`,
        source: `course_${termIndex}_${e.from}`,
        target: `course_${termIndex}_${e.to}`,
        type: 'coRequisiteEdge',
        sourceHandle: `s_${termIndex}_${index}`,
        targetHandle: `t_${termIndex}_${index}`,
        data: { index, maxIndex: all.length - 1, horizontalMode },
        animated: [e.from, e.to].includes(
          selectedId?.replace(`course_${termIndex}_`, '') ?? ''
        ),
      }))
  );
};