import React from 'react';
import styled from 'styled-components';
import { Handle, Position } from 'react-flow-renderer';
import type { ProgramMapCourse, ProgramCourseMapProps } from '../types';

export type Props<T extends object = {}> = {
  course: ProgramMapCourse<T>;
  termIndex: number;
  ordinals: string[];
  isTarget?: boolean;
  isSource?: boolean;
  nodeOverride?: ProgramCourseMapProps<T>['nodeOverride'];
  selectedId?: string;
  horizontalMode?: boolean;
  customHandles?: string[];
};

const CourseNode = <T extends object = {}>({
  data: {
    course,
    termIndex,
    ordinals,
    isTarget,
    isSource,
    nodeOverride,
    selectedId,
    horizontalMode,
    customHandles,
  },
}: {
  data: Props<T>;
}) => {
  const node = (
    <Container>
      <CourseCode>{course.courseCode}</CourseCode>
      <CourseTitle title={course.title}>{course.title}</CourseTitle>
    </Container>
  );
  return (
    <>
      {isTarget && (
        <NodeHandle
          type="target"
          position={horizontalMode ? Position.Top : Position.Left}
          isConnectable={false}
        />
      )}
      {nodeOverride?.(
        course,
        termIndex,
        node,
        selectedId === `course_${termIndex}_${course.courseCode}`,
        ordinals
      ) ?? node}
      {isSource && (
        <NodeHandle
          type="source"
          position={horizontalMode ? Position.Bottom : Position.Right}
          isConnectable={false}
        />
      )}
      {customHandles
        ?.filter((ch) => ch.startsWith('s_'))
        .map((s, index) => (
          <NodeHandle
            id={s}
            key={s}
            type="source"
            position={horizontalMode ? Position.Top : Position.Left}
            isConnectable={false}
            style={
              horizontalMode
                ? { left: `calc(50% + ${10 * (index + 1)}px)` }
                : { top: `calc(50% + ${10 * (index + 1)}px)` }
            }
          />
        ))}
      {customHandles
        ?.filter((ch) => ch.startsWith('t_'))
        .map((t, index) => (
          <NodeHandle
            id={t}
            key={t}
            type="target"
            position={horizontalMode ? Position.Top : Position.Left}
            isConnectable={false}
            style={
              horizontalMode
                ? { left: `calc(50% - ${10 * (index + 1)}px)` }
                : { top: `calc(50% - ${10 * (index + 1)}px)` }
            }
          />
        ))}
    </>
  );
};

export default CourseNode;

const Container = styled.div.attrs({ className: 'sc-courseNode' })`
  width: 150px;
  text-align: left;
  font-size: 10pt;
  padding-left: 0px;
  box-sizing: border-box;
`;
const CourseCode = styled.div`
  font-weight: 500;
`;
const CourseTitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const NodeHandle = styled(Handle)`
  width: 8px;
  height: 8px;
`;
