import "./types/common";
import { useState, useRef, useEffect, Dispatch, useCallback } from "react";
import debounce from "lodash/debounce";
import type { DebouncedFunc } from "lodash-es";

/** Get previous props or state. */
const usePrevious = <T>(state: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
};

/** Get multiple useState. */
const useMapStates = <T extends any[]>(...initStates: T) =>
  initStates.map((s) => useState(s)) as {
    [K in keyof T]: [T[K], Dispatch<T[K]>];
  };

/** A simple debounce function for react hooks. */
const useDebounce = <T extends any>(
  initState: T,
  wait?: number
): [T, DebouncedFunc<Dispatch<T>>, Dispatch<T>] => {
  const [state, setState] = useState(initState);
  return [state, useCallback(debounce(setState, wait), [state]), setState];
};

/** Get multiple useState in a single array. */
const useMultiStates = <T extends any[]>(...states: T) =>
  states.flatMap((s) => useState(s)) as ConcatAll<{
    [K in keyof T]: [T[K], Dispatch<T[K]>];
  }>;

/** A useState helper for a single object state, auto generate the set value methods. */
const useOneState = <T extends object>(initState: T) => {
  const [state, setState] = useState(initState);

  const getUpdater = (currState: object = state) => {
    const updater = {} as ObjectType;
    for (const key in state) {
      updater[`set${key[0].toUpperCase()}${key.substring(1)}`] = (
        value: T[typeof key]
      ) => {
        const newState = { ...currState, [key]: value };
        return {
          ...getUpdater(newState),
          commit: () => setState(newState as T),
        };
      };
    }
    return updater;
  };

  const setter = {} as ObjectType;
  for (const key in state) {
    setter[`setOnly${key[0].toUpperCase()}${key.substring(1)}`] = (
      value: T[typeof key]
    ) => setState({ ...state, [key]: value } as T);
  }

  return [state, { ...setter, updater: getUpdater(), setState }] as [
    T,
    StateSetter<T>
  ];
};

/** A useState helper for state that can be undo or redo. */
const useUndoableState = <T>(initState: T, skipInitialState?: boolean) => {
  const [{ state, records, cursor }, { updater }] = useOneState({
    state: initState,
    records: skipInitialState ? [] : [initState],
    cursor: skipInitialState ? -1 : 0,
  });

  const redoable = cursor < records.length - 1;
  const redo = () => {
    if (!redoable) return;
    updater
      .setState(records[cursor + 1])
      .setCursor(cursor + 1)
      .commit();
  };
  const undoable = cursor > 0;
  const undo = () => {
    if (!undoable) return;
    updater
      .setState(records[cursor - 1])
      .setCursor(cursor - 1)
      .commit();
  };
  const setState = (newState: T, clearHistory?: boolean) => {
    updater
      .setState(newState)
      .setRecords(
        clearHistory ? [newState] : [...records.slice(0, cursor + 1), newState]
      )
      .setCursor(clearHistory ? 0 : cursor + 1)
      .commit();
  };

  return [state, setState, { redoable, redo, undoable, undo }] as [
    T,
    (value: T, clearHistory?: boolean) => void,
    { redoable: boolean; redo: () => void; undoable: boolean; undo: () => void }
  ];
};

type SetStateChain<T extends object> = {
  [K in keyof T as `set${Capitalize<string & K>}`]: (
    value: T[K]
  ) => SetStateChain<Omit<T, K>> & { commit: () => void };
};

type StateSetter<T extends object> = {
  [K in keyof T as `setOnly${Capitalize<string & K>}`]: Dispatch<T[K]>;
} & {
  updater: SetStateChain<T>;
  setState: Dispatch<T>;
};

export {
  usePrevious,
  useMapStates,
  useDebounce,
  useMultiStates,
  useOneState,
  useUndoableState,
};

export type { StateSetter };
