import { getConnectors } from "./components/Elements";
import countBy from "lodash/countBy";
import entries from "lodash/entries";
import type { ProgramCourseMapProps as Props, ProgramMapCourse } from "./types";

const getMaxPreReqTerm = (courses: ProgramMapCourse[], course: ProgramMapCourse) =>
  courses
    .filter((c) => course.preRequisites.includes(c.courseCode))
    .map((c) => c.atTermIndexes)
    .flat()
    .max() ?? -1;

const markCoReqs = <T extends object = {}>(courses: ProgramMapCourse<T>[]): ProgramMapCourse<T>[] => {
  const coReqItems = courses
    .filter((c) => c.coRequisites.length > 0 && c.atTermIndexes.length > 0)
    .flatMap((c) =>
      c.coRequisites.map((co) => ({ codes: [co, c.courseCode], groupId: undefined as number | undefined }))
    )
    .uniq();
  let id = 1;
  coReqItems.forEach((ci, _, all) => {
    const match = all.find((a) => a.codes.find((x) => ci.codes.includes(x)))?.groupId;
    if (!match) {
      ci.groupId = id;
      id++;
    } else ci.groupId = match;
  });

  return courses.map((c) => {
    const found = coReqItems.find((ci) => ci.codes.includes(c.courseCode));
    return found ? { ...c, coReqGroupId: found.groupId } : c;
  });
};

export const polishProgramMap = <T extends object = {}>(courses: ProgramMapCourse<T>[]): ProgramMapCourse<T>[] =>
  markCoReqs(courses).map((c, _, array) => {
    const extra: string[] = [];
    c.preRequisites.forEach((p) => {
      const pCourse = array.find((r) => r.courseCode === p);
      if (!pCourse) extra.push(p);
      else {
        pCourse.preRequisites.forEach((pc) => {
          if (c.preRequisites.includes(pc)) extra.push(pc);
        });
      }
    });

    return { ...c, preRequisites: c.preRequisites.without(...extra) };
  });

export const isProgramMapValid = (courses: ProgramMapCourse[]) => {
  const polished = polishProgramMap(courses);

  return (
    !polished.map((p) => p.canCourseInTermIndex?.(p.atTermIndexes.max()!)).includes(false) &&
    !polished
      .filter((c) => c.preRequisites.length > 0 && c.atTermIndexes.length > 0)
      .map((p) => getMaxPreReqTerm(courses, p) < p.atTermIndexes.max()!)
      .includes(false)
  );
};

export const fixProgramMap = <T extends object = {}>(courses: ProgramMapCourse<T>[]) => {
  const isValid = isProgramMapValid(courses);
  if (isValid) return { isValid };

  const fixed = polishProgramMap(courses);
  do {
    fixed.forEach((c) => {
      const termIndex = c.atTermIndexes.max()!;
      if (c.canCourseInTermIndex?.(termIndex) === false) {
        c.atTermIndexes = [...c.atTermIndexes.without(termIndex), termIndex + 1];
      } else if (c.preRequisites.length > 0 && c.atTermIndexes.length > 0) {
        const maxPreReqTerm = getMaxPreReqTerm(fixed, c);
        if (termIndex <= maxPreReqTerm) {
          c.atTermIndexes = [...c.atTermIndexes.without(termIndex), maxPreReqTerm + 1];
        }
      }
    });
  } while (!isProgramMapValid(fixed));

  const delta = courses
    .map((c) => ({
      courseCode: c.courseCode,
      old: c.atTermIndexes,
      new: fixed.find((f) => f.courseCode === c.courseCode)!.atTermIndexes,
    }))
    .filter((d) => !d.new.isEqualTo(d.old));

  return { isValid, fixed, delta };
};

export const getInvalidConnectors = (courses: ProgramMapCourse[], connectorMode?: Props["connectorMode"]) =>
  getConnectors(courses, connectorMode)
    .filter((e) => e.invalid)
    .map((i) => i.invalid!);

export const getMaxTermCoursesCount = (courses: ProgramMapCourse[]) =>
  entries(countBy(courses.flatMap((c) => c.atTermIndexes))).max((e) => e[1])?.[1] ?? 0;

export const getMaxTerm = (courses: ProgramMapCourse[]) => courses.flatMap((p) => p.atTermIndexes).uniq().length;

export const getAllPostRequs = (courses: ProgramMapCourse[], courseCode: string) => {
  let postRequs = courses.filter((c) => c.preRequisites.includes(courseCode)).map((c) => c.courseCode);
  const ret: string[] = [];

  do {
    ret.push(...postRequs);
    const rest = courses.filter((c) => !ret.includes(c.courseCode));
    postRequs = postRequs
      .flatMap((p) => rest.filter((r) => r.preRequisites.includes(p)).map((c) => c.courseCode))
      .uniq();
  } while (postRequs.length > 0);

  return ret.orderBy();
};
