import "./types/global";
import compact from "lodash/compact";
import chunk from "lodash/chunk";
import curry from "lodash/curry";
import isEqual from "lodash/isEqual";
import max from "lodash/max";
import maxBy from "lodash/maxBy";
import mean from "lodash/mean";
import meanBy from "lodash/meanBy";
import min from "lodash/min";
import minBy from "lodash/minBy";
import orderBy from "lodash/orderBy";
import sum from "lodash/sum";
import sumBy from "lodash/sumBy";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import without from "lodash/without";

Array.prototype.avg = function (iteratee: any) {
  return iteratee ? meanBy(this, iteratee) : mean(this);
};

Array.prototype.compact = function () {
  return compact(this);
};

Array.prototype.chunk = function (size) {
  return chunk(this, size);
};

Array.prototype.isEqualTo = function (compareTo) {
  return isEqual(this, compareTo);
};

Array.prototype.max = function (iteratee) {
  return iteratee ? maxBy(this, iteratee) : max(this);
};

Array.prototype.maxOf = function (key) {
  return maxBy(this, key)?.[key];
};

Array.prototype.min = function (iteratee) {
  return iteratee ? minBy(this, iteratee) : min(this);
};

Array.prototype.minOf = function (key) {
  return minBy(this, key)?.[key];
};

Array.prototype.orderBy = function (...args: any[]) {
  if (Array.isArray(args[0])) {
    const identity: any[] = [];
    const order: any[] = [];
    args.forEach((e) => {
      identity.push(e[0]);
      order.push(e[1]);
    });
    return orderBy(this, identity, order);
  }
  if (args.length <= 1 && ["asc", "desc", undefined].includes(args[0])) {
    return orderBy(this, undefined, args[0]);
  }
  return orderBy(this, args[0], args[1]);
};

Array.prototype.sum = function (iteratee: any) {
  return iteratee ? sumBy(this, iteratee) : sum(this);
};

Array.prototype.uniq = function (iteratee) {
  return iteratee ? uniqBy(this, iteratee) : uniq(this);
};

Array.prototype.without = function (...args) {
  return without(this, ...args);
};

Function.prototype.curry = function (...headArgs) {
  return curry(this)(...headArgs);
};

Function.prototype.partial = function (...headArgs) {
  return (...tailArgs) => this(...headArgs, ...tailArgs);
};

Function.prototype.partialArg = function (arg: any) {
  return (restArg) => this({ ...arg, ...restArg });
};

Function.prototype.compose = function (func) {
  return (...args) => func(this(...args));
};

Number.prototype.pad = function (size) {
  return String(this).padStart(size, "0");
};

Object.defineProperty(Object, "isEqual", {
  value: (a: object, b: object) => isEqual(a, b),
  writable: true,
  enumerable: false,
  configurable: true,
});
