import React, { memo } from 'react';
import styled from 'styled-components';

export type Props = {
  termIndex: number;
  titleExtra?: string;
  titleOverride?: string | JSX.Element;
};

const TermNode = memo(
  ({ data: { termIndex, titleExtra, titleOverride } }: { data: Props }) => {
    if (
      typeof titleOverride !== 'undefined' &&
      typeof titleOverride !== 'string'
    )
      return titleOverride;

    return (
      <Container>
        {titleOverride?.trim()
          ? titleOverride
          : `Term ${termIndex + 1}${titleExtra ?? ''}`}
      </Container>
    );
  }
);

export default TermNode;

const Container = styled.h2`
  width: 150px;
  text-align: left;
  font-size: 10pt;
`;
